canvas {
    height: 500px;
    
}
h1{
font-family: 'Comforter', cursive;
text-align: center;
font-size: 64px;
color: white;
}

h3{
    font-family: 'Comforter', cursive;
text-align: center;
}
a{
    color: white;
}

a:hover{
    color: orangered ;
}

a:link {
    text-decoration: none;
}
