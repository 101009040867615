.proj{
    color: white;
    font-family: 'Source Sans Pro', sans-serif;
}
a{
    color: orangered;
    
}
a:hover{
    color: hotpink;
}