#name{
    font-family: 'Comforter', cursive;
    color: orangered;
}
i{
    color: black;
}
.about{
    color: white;
    font-family: 'Comforter', cursive;
font-family: 'Source Sans Pro', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    background-color: #2f2f2f;
  }
  .main {
    height: 100vh;
    width: 100vw;
    position: relative;
  }
  .d1 {
    position: absolute;
    background-image: url(https://newevolutiondesigns.com/images/freebies/4k-wallpaper-3.jpg);
    background-size: 2700px 1500px;
  
    height: 30vh;
    width: 15vw;
  
    background-position: 0 50%;
  
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.8);
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    animation: dd1 10s 1, dd12 10s 1;
    animation-delay: 4s, 14s;
  }
  .d2 {
    position: absolute;
    background-image: url(https://newevolutiondesigns.com/images/freebies/4k-wallpaper-3.jpg);
    background-size: 2700px 1500px;
  
    height: 50vh;
    width: 25vw;
  
    background-position: -10vw 50%;
    left: 10vw;
  
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    animation: dd2 10s 2;
    animation-delay: 4s;
  }
  .d3 {
    position: absolute;
    background-image: url(https://newevolutiondesigns.com/images/freebies/4k-wallpaper-3.jpg);
    background-size: 2700px 1500px;
    overflow: hidden;
  
    height: 100vh;
    width: 40vw;
    left: 25vw;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.8);
    background-position: -35vw 50%;
  
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    animation: dd3 10s 2;
    animation-delay: 4s;
  }
  .d4 {
    position: absolute;
    overflow: hidden;
    background-image: url(https://newevolutiondesigns.com/images/freebies/4k-wallpaper-3.jpg);
    background-size: 2700px 1500px;
  
    height: 80vh;
    width: 25vw;
    left: 60vw;
    background-position: -70vw 50%;
  
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    animation: dd4 10s 2;
    animation-delay: 4s;
  }
  
  @keyframes dd1 {
    0% {
    }
    50% {
      width: 95vw;
    }
  }
  @keyframes dd12 {
    0% {
    }
    50% {
      background-position: Calc(0vw - 40px) 50%;
    }
  }
  @keyframes dd2 {
    0% {
    }
    50% {
      background-position: Calc(-10vw - 40px) 50%;
    }
  }
  @keyframes dd3 {
    0% {
    }
    50% {
      background-position: Calc(-35vw - 40px) 50%;
    }
  }
  @keyframes dd4 {
    0% {
    }
    50% {
      background-position: Calc(-70vw - 40px) 50%;
    }
  }